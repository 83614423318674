body {
  background: #fff;
  margin: 0;
  padding: 0;
  height: auto;
  color: #333;
}

#container {
  padding: 0;
}

.logo-image {
  width: 115px;
  margin: 142px auto 32px auto;
}

.logo-text {
  font-size: 40px;
  font-weight: 700;
  color: #000;
  text-align: center;
}

body.controller-auth-providers-local .login-form {
  max-width: 500px;
  text-align: center;
}

.form-group {
  margin-bottom: 2px;
}

.col-form-label {
  display: none;
}

.input-container {
  margin: 0;
}

.input, input[type="text"], input[type="password"], input[type="email"] {
  font-size: 14px;
  background: #f3f5f7;
  border: none;
  padding: 30px 26px;
  margin: 0;
  &:focus {
    padding: 30px 26px;
    border: none;
  }
}

::placeholder {
  color: #111213;
  opacity: 0.4;
}

form .actions {
  width: 100%;
  float: none;
  margin: 2px 0 22px 0;
  padding: 0;
}

label {
  color: inherit;
}

.btn {
  font-size: 16px;
  color: #000;
  &:hover{
    opacity: 0.75;
  }
}

.btn-success {
  background-color: #705fc8 !important;
  color: #fff;
  width: 100%;
}

a {
  color: #5d5c65;
  font-size: 14px;
  font-weight: normal;
}

.alert {
  color: #fc5f69;
  width: 500px;
  border: 1px solid #fc5f69;
  margin: 30px auto;
  font-size: 14px;
  text-align: center;
  border-radius: 0;
}

.context-message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  color: black;
  margin: 0;
  text-align: center;
  background-color: #D1ECF1FF;
  font-size: 16px;
  padding: 0.75rem 0;

  a {
    font-size: 16px;
  }
}

.page-header {
  margin: 3em 0;
  .title {
    margin-bottom: 0;
  }
}

.program-disambiguation-results {
  .item a {
    margin: 0.5em 0;
    height: 56px;
    width: 100%;
    text-align: left;
  }

  .item {
    border-bottom: 1px solid #eaeaea;
    width: 100%;
    &:last-of-type {
      border-bottom: 0px;
    }
  }

  .item .icon {
    display: block;
    float: left;
    height: 36px;
    width: 40px;
    transform: translateX(-50%);

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  .item .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000;
    font-size: 1.125em;
    line-height: 1.2;
    font-weight: 500;
    text-transform: uppercase;
    display: block;
  }

  .item .description {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: .875em;
    line-height: 1.2;
    font-weight: 300;
    color: #bbbbbb;
  }

  margin: 0;
  .list {
    margin: 50px 0;
  }

  .no-results {
    margin: 3em 0;
  }
}

.additional-actions {
  margin: 3em 0;
  text-align: left;
}

.program-header {
  text-align: center;
  margin: 3em 0;

  .logo {
    margin: 1em 0;
  }

  .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000;
    font-size: 1.125em;
    line-height: 1.2;
    font-weight: 500;
    text-transform: uppercase;
    display: block;
  }

  .description {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: .875em;
    line-height: 1.2;
    font-weight: 300;
    color: #bbbbbb;
  }

}

.instructions {
  font-family: Inter,sans-serif;
  font-size: 13px;
  opacity: 0.65;
  font-weight: 500;
  margin-top: 20px;
  padding-left: 12px;
  border-left: 6px solid rgba(0, 0, 0, 0.04);
}

ul.instructions {
  list-style: none;
}
